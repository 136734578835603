import { Fragment, useEffect, useRef, useState } from "react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import { SwiperRef } from "swiper/react";
import Swiper from "swiper";
import WebpImage from "../WebpImage";
import { Icon } from "@iconify/react/dist/iconify.js";

const SliderWithDescription = () => {
    const swiperRef = useRef<SwiperRef>(null);
    const [realIndex, setRealIndex] = useState<number>(1);

    useEffect(() => {
        // @ts-ignore
        const swiperContainer = swiperRef.current;
        const params = {
            slidesPerView: 1,
            grabCursor: false,
            speed: 750,
            loop: true,
            navigation: {
                prevEl: '.controlled-swiper-prev-slide-desc',
                nextEl: '.controlled-swiper-next-slide-desc',
            },
            on: {
                realIndexChange: (swiper: Swiper) => {
                    setRealIndex(swiper.realIndex + 1);
                },
            },
        };
        // @ts-ignore
        Object.assign(swiperContainer, params);
        // @ts-ignore
        swiperContainer.initialize();
    }, [swiperRef])

    return (
        <>
            <section className="bg-primary lg:pt-12 pt-9 pb-0 lg:pb-16 lg:mb-26 mb-0 relative lg:block flex flex-col" id="o-inwestycji">
                <div className="lg:max-w-[53.5%] max-w-full w-full lg:absolute static lg:h-9 left-14 lg:-mt-[135px] mt-4 lg:order-1 order-2 h-auto">
                    {/* @ts-ignore */}
                    <swiper-container ref={swiperRef} init="false">
                        <Fragment key={1}>
                            {/* @ts-ignore */}
                            <swiper-slide>
                                <WebpImage
                                    fallback="/storage/static/AERIAL_CAM_01_NIGHT_V1.jpg"
                                    className="lg:h-[775px] w-full object-cover maxMd:h-auto"
                                    lazy
                                    width={1051}
                                    height={775}
                                    alt="O inwestycji 1"
                                />
                                {/* @ts-ignore */}
                            </swiper-slide>
                            {/* @ts-ignore */}
                            <swiper-slide>
                                <WebpImage
                                    fallback="/storage/static/CAM_04_V6.jpg"
                                    className="lg:h-[775px] w-full object-cover maxMd:h-auto"
                                    lazy
                                    width={1051}
                                    height={775}
                                    alt="O inwestycji 2"
                                />
                                {/* @ts-ignore */}
                            </swiper-slide>
                        </Fragment>
                        {/* @ts-ignore */}
                    </swiper-container>
                    <div className="nav lg:block hidden">
                        <div className="ml-[11%] maxMd:ml-0">
                            <div className="flex -mt-8 gap-x-4 maxMd:mt-0">
                                <button type="button" className="maxMd:hidden glass-style px-6 py-3 rounded-[60px] relative z-20 controlled-swiper-prev-slide-desc">
                                    <Icon icon={`oui:arrow-left`} className="text-primary" width={40} height={40} />
                                </button>
                                <div className="flex relative z-20 gap-x-6 self-center">
                                    {[...Array(2)].map((_, i) => (
                                        <div key={i} className={`${realIndex == i + 1 ? 'bg-secondary' : 'bg-spot'} w-3 h-3 rounded-full transition-all ease-in-out duration-200`}>
                                        </div>
                                    ))}
                                </div>
                                <button type="button" className="hidden lg:block glass-style px-6 py-3 rounded-[60px] relative z-20 controlled-swiper-next-slide-desc">
                                    <Icon icon={`oui:arrow-right`} className="text-primary" width={40} height={40} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Container className="lg:order-2 order-1">
                    <Row>
                        <Column xl={5} lg={5} md={12} className="ml-auto">
                            <div className="lg:pl-8 lg:pb-[225px] pl-0 pb-0">
                                <p className="text-label leading-label uppercase font-extrabold text-white mb-2">O inwestycji</p>
                                <h1 className="text-h2 leading-h2 mb-8 font-bold text-white tracking-heading uppercase maxMd:mb-6">Zwycięski punkt na mapie Gdańska</h1>
                                <p className="text-p leading-p text-white mb-6 tracking-heading maxMd:mb-4"><strong>Kusocińskiego</strong> to nowa inwestycja zlokalizowana na gdańskiej Morenie. Wyróżnia się nowoczesnością, a z drugiej strony - doskonale wpisuje się w tradycję dzielnicy, w której żyje się aktywnie i wygodnie zarazem.</p>
                                <p className="text-p leading-p text-white mb-6 tracking-heading maxMd:mb-4"><strong>Morena</strong> to jedna z najpopularniejszych dzielnic miasta. Ceniona jest za doskonałą infrastrukturę oraz znakomitą ofertę handlową, edukacyjną i usługową.</p>
                                <p className="text-p leading-p text-white tracking-heading maxMd:mb-4">Okolica słynie również z imponujących terenów zielonych. Z Trójmiejskiego Parku Krajobrazowego bije zielona energia!</p>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </section>
            <div className="block lg:hidden mt-6 mb-14">
                <div className="flex relative z-20 gap-x-6 justify-center">
                    {[...Array(2)].map((_, i) => (
                        <div key={i} className={`${realIndex == i + 1 ? 'bg-primary' : 'bg-secondary'} w-3 h-3 rounded-full transition-all ease-in-out duration-200`}>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default SliderWithDescription;