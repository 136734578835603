import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";

const LandscapeWithBox = () => {
    return (
        <section className="mb-20 maxMd:mb-8">
            <div className="px-14 maxMd:px-0 maxMd:mb-8">
                <WebpImage
                    width={1800}
                    height={800}
                    fallback="/storage/static/kusocinskiego-blok-krajobraz.jpg"
                    lazy
                    alt="Kusociński blok"
                />
            </div>
            <Container className="-mt-26 maxMd:mt-0">
                <Row className="justify-center">
                    <Column xl={8} sm={12} className="maxMd:-mx-4 maxMd:!px-0">
                        <div className="glass-style p-14 text-center maxMd:unset-glass maxMd:shadow-xl maxMd:pt-0 maxMd:pb-8 maxMd:px-4">
                            <h3 className="text-h2 leading-h2 text-primary font-extrabold uppercase mb-6 maxMd:mb-8">
                                Przekazaliśmy pałeczkę <br /> <span className="text-secondary">świetnym architektom</span>
                            </h3>
                            <p className="text-text text-p leading-p [&>strong]:text-primary [&>strong]:font-extrabold">
                            <strong>W każdym mieszkaniu,</strong> w zależności od kondygnacji i umiejscowienia, znajduje się taras, balkon lub ogródek. Architektura kompleksu mieszkalnego Kusocińskiego jest elegancka i przemyślana.
                            </p>
                        </div>
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default LandscapeWithBox;