import { SearchEngineData } from "@/types/searchEngineData";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import ReactSlider from "react-slider";
import { ChangeEvent, FormEventHandler, useEffect, useState } from "react";
import { useForm } from "@inertiajs/react";

const ApartmentSearch = ({ searchEngineParams }: { searchEngineParams: SearchEngineData }) => {
    const { data, setData, get, processing } = useForm({
        minArea: searchEngineParams.minArea,
        maxArea: searchEngineParams.maxArea,
        minRooms: searchEngineParams.minRooms,
        maxRooms: searchEngineParams.maxRooms,
        minFloor: searchEngineParams.minFloor,
        maxFloor: searchEngineParams.maxFloor,
        garden: 0,
        terrace: 0,
        loggia: 0
    });

    const [shiftedLabelLeft, setShiftedLabelLeft] = useState<boolean>(false);

    const handleSurfaceSliderChange = (value: number[], index: number) => {
        const [minValue, maxValue] = value;

        setData(data => ({
            ...data,
            minArea: minValue,
            maxArea: maxValue,
        }));

        if (maxValue - minValue <= 25) {
            setShiftedLabelLeft(true)
        } else {
            setShiftedLabelLeft(false);
        }
    }

    const handleRoomsSliderChange = (value: number[], index: number) => {
        const [minValue, maxValue] = value;

        setData(data => ({
            ...data,
            minRooms: minValue,
            maxRooms: maxValue,
        }));
    }

    const handleFloorSliderChange = (value: number[], index: number) => {
        const [minValue, maxValue] = value;

        setData(data => ({
            ...data,
            minFloor: minValue,
            maxFloor: maxValue,
        }));
    }

    const handleApartmentSearch: FormEventHandler = (e) => {
        e.preventDefault();

        get(route('frontend.apartments', {
            _query: {
                variation: '2d'
            }
        }));
    }

    return (
        <section className={`bg-[url('@static/find-apartment-bg.webp')] maxMd:bg-[url()] bg-center bg-no-repeat bg-cover py-26 mb-26 maxMd:pt-0 maxMd:pb-8 maxMd:mb-22`}>
            <Container>
                <Row className="justify-center">
                    <Column xl={10}>
                        <div className="px-4 maxMd:px-0">
                            <h4 className="text-h3 leading-h3 text-primary font-extrabold mb-8 text-center uppercase">Znajdź swoje wymarzone mieszkanie</h4>
                            <form className="filter-area bg-white py-8 px-10 rounded-2xl flex flex-wrap items-center gap-x-12 shadow-2xl maxMd:px-0 maxMd:shadow-none maxMd:pb-0" onSubmit={handleApartmentSearch}>
                                <div className="max-w-[288px] w-full maxMd:max-w-[98%] maxMd:mb-8">
                                    <p className="text-label leading-label text-primary uppercase font-extrabold mb-4">Powierzchnia</p>
                                    <ReactSlider
                                        className="apartments-range-slider"
                                        thumbClassName="apartments-range-slider-thumb"
                                        trackClassName="apartments-range-slider-track"
                                        defaultValue={[Math.floor(searchEngineParams.minArea), Math.ceil(searchEngineParams.maxArea)]}
                                        min={Math.floor(searchEngineParams.minArea)}
                                        max={Math.ceil(searchEngineParams.maxArea)}
                                        ariaLabel={['Miminalna powierzchnia suwak', 'Maksymalna powierzchnia suwak']}
                                        key={`area-slider`}
                                        renderThumb={(props, state) =>
                                            <div key={state.index+1+`area`}>
                                                <div key={state.index} {...props} className={`tracking-label-slide tracking-label-side-${state.index} text-primary font-extrabold text-label leading-label uppercase whitespace-pre`}>
                                                    <div className={`h-4 w-4 rounded-full bg-secondary -mt-[6px] ${state.index == 1 ? 'ml-auto' : ''}`}></div>

                                                </div>
                                                <p key={state.index+1} className={`mt-2 absolute bottom-2 ${state.index === 1 ? 'right-0' : ''} text-primary font-extrabold text-label leading-label uppercase whitespace-pre`}>
                                                    {state.valueNow} M<sup>2</sup>
                                                </p>
                                            </div>
                                        }
                                        step={1}
                                        onChange={handleSurfaceSliderChange}
                                    />
                                </div>
                                <div className="max-w-[288px] w-full maxMd:max-w-[98%] maxMd:mb-8">
                                    <p className="text-label leading-label text-primary uppercase font-extrabold mb-4">Liczba pokoi</p>
                                    <ReactSlider
                                        className="apartments-range-slider"
                                        thumbClassName="apartments-range-slider-thumb"
                                        trackClassName="apartments-range-slider-track"
                                        defaultValue={[searchEngineParams.minRooms, searchEngineParams.maxRooms]}
                                        min={searchEngineParams.minRooms}
                                        max={searchEngineParams.maxRooms}
                                        onChange={handleRoomsSliderChange}
                                        ariaLabel={['Miminalna ilość pokoi suwak', 'Maksymalna ilość pokoi suwak']}
                                        key={`rooms-slider`}
                                        renderThumb={(props, state) =>
                                            <div key={state.index+1+`rooms`}>
                                                <div {...props} className={`tracking-label-slide tracking-label-side-${state.index} text-primary font-extrabold text-label leading-label uppercase whitespace-pre`}>
                                                    <div className={`h-4 w-4 rounded-full bg-secondary -mt-[6px] ${state.index == 1 ? 'ml-auto' : ''}`}></div>

                                                </div>
                                                <p className={`mt-2 absolute bottom-2 ${state.index === 1 ? 'right-0' : ''} text-primary font-extrabold text-label leading-label uppercase whitespace-pre`}>
                                                    {state.valueNow}
                                                </p>
                                            </div>
                                        }
                                        step={1}
                                    />
                                </div>
                                <div className="max-w-[288px] w-full maxMd:max-w-[98%] maxMd:mb-8">
                                    <p className="text-label leading-label text-primary uppercase font-extrabold mb-4">Piętro</p>
                                    <ReactSlider
                                        className="apartments-range-slider"
                                        thumbClassName="apartments-range-slider-thumb"
                                        trackClassName="apartments-range-slider-track"
                                        defaultValue={[searchEngineParams.minFloor, searchEngineParams.maxFloor]}
                                        min={searchEngineParams.minFloor}
                                        max={searchEngineParams.maxFloor}
                                        onChange={handleFloorSliderChange}
                                        key={`floor-slider`}
                                        ariaLabel={['Miminalne piętro suwak', 'Maksymalne piętro suwak']}
                                        renderThumb={(props, state) =>
                                            <div key={state.index+1+`floor`}>
                                                <div {...props} className={`tracking-label-slide tracking-label-side-${state.index} text-primary font-extrabold text-label leading-label uppercase whitespace-pre`}>
                                                    <div className={`h-4 w-4 rounded-full bg-secondary -mt-[6px] ${state.index == 1 ? 'ml-auto' : ''}`}></div>

                                                </div>
                                                <p className={`mt-2 absolute bottom-2 ${state.index === 1 ? 'right-0' : ''} text-primary font-extrabold text-label leading-label uppercase whitespace-pre`}>
                                                    {state.valueNow}
                                                </p>
                                            </div>
                                        }
                                        step={1}
                                    />
                                </div>
                                <div className="ml-auto maxMd:order-3 maxMd:ml-0 maxMd:w-full maxMd:mt-10">
                                    <button type="submit" disabled={processing ? true : false} className="button-primary maxMd:w-full" title="Szukaj mieszkań">
                                        Szukaj
                                    </button>
                                </div>
                                <div className="w-full mt-10 gap-x-22 flex items-center justify-center maxMd:justify-start flex-wrap maxMd:pl-6 maxMd:mt-0">
                                    <div className="relative maxMd:mb-8">
                                        <input type="checkbox" name="garden" id="garden" className="hidden filter-apartment-checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('garden', e.currentTarget.checked ? 1 : 0)} />
                                        <label htmlFor="garden" className="text-text cursor-pointer text-label leading-label font-extrabold uppercase pl-4 filter-apartment-label">Ogród</label>
                                    </div>
                                    <div className="relative maxMd:mb-8">
                                        <input type="checkbox" name="terrace" id="terrace" className="hidden filter-apartment-checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('terrace', e.currentTarget.checked ? 1 : 0)} />
                                        <label htmlFor="terrace" className="text-text cursor-pointer text-label leading-label font-extrabold uppercase pl-4 filter-apartment-label">Taras</label>
                                    </div>
                                    <div className="relative maxMd:mb-8">
                                        <input type="checkbox" name="loggia" id="loggia" className="hidden filter-apartment-checkbox" onChange={(e: ChangeEvent<HTMLInputElement>) => setData('loggia', e.currentTarget.checked ? 1 : 0)} />
                                        <label htmlFor="loggia" className="text-text cursor-pointer text-label leading-label font-extrabold uppercase pl-4 filter-apartment-label">Balkon/Loggia</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default ApartmentSearch;