import useMediaQuery from "@/Hooks/useMediaQuery";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";

const AboutInvestor = () => {
    const isMobile = useMediaQuery();
    return (
        <section id="inwestor" className={`bg-[url('@static/sea-background.webp')] bg-center bg-no-repeat bg-cover pt-26 pb-26 maxMd:pb-14 maxMd:pt-6`}>
            <Container>
                <Row>
                    <Column>
                        <h5 className="text-primary text-h2 leading-h2 font-extrabold mb-10 uppercase text-center maxMd:mb-8">Inwestor</h5>
                    </Column>
                    <Column>
                        {!isMobile ?
                            <WebpImage
                                width={1600}
                                height={648}
                                fallback="/storage/static/investor-img.png"
                                lazy
                                className="w-full h-auto object-cover mb-10"
                                alt="Unidevelopment budynki"
                            />
                            :
                            <WebpImage
                                width={767}
                                height={500}
                                fallback="/storage/static/investor-img-mobile.png"
                                lazy
                                className="w-full h-auto object-cover mb-10"
                                alt="Unidevelopment budynki"
                            />
                        }
                    </Column>
                </Row>
                <Row className="justify-center">
                    <Column xl={8}>
                        <img src="/storage/static/unidevelopment-logo.svg" alt="Logo Unidevelopment" className="mx-auto mb-8" width={434} height={60} />
                    </Column>
                </Row>
                <Row className="justify-center">
                    <Column xl={8}>
                        <p className="text-p leading-p text-text text-center mb-6 maxMd:mb-4 maxMd:text-left">W 2024 roku upływa 16 lat od rozpoczęcia naszej działalności. W tym czasie zdobyliśmy ugruntowaną pozycję na rynku budownictwa mieszkaniowego. Nasza spółka, należąca do Grupy UNIBEP SA, z każdym rokiem konsekwentnie rozwija się realizując nowe projekty oraz stawiając na wartości takie jak jakość, profesjonalizm i bezpieczeństwo.</p>
                        <p className="text-p leading-p text-text text-center maxMd:mb-4 maxMd:text-left">Od 2008 roku obecni jesteśmy w Warszawie, od 2012 w Poznaniu, a od 2016 roku także w Radomiu. W 2022 roku rozpoczęliśmy działalność w Trójmieście. Nasza spółka zrealizowała łącznie ponad 40 inwestycji, w których oddała do użytku około 6000 mieszkań i domów jednorodzinnych oraz niemal 160 lokali usługowych. Ich całkowita powierzchnia to ponad 300.000 metrów kwadratowych. A w przygotowaniu mamy kolejne ciekawe projekty.</p>
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default AboutInvestor;