import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import WebpImage from "../WebpImage";

const ChoiceOnMedal = () => {
    return(
        <Container className="xl:mb-26 lg:mb-[250px] mb-14">
            <Row className="relative">
                <Column xl={6} lg={6}>
                    <img
                        src="/storage/static/kusocinskiego-signet.svg" 
                        alt="Sygnet kusocińskiego"
                        width={225}
                        height={225}
                        className="mx-auto lg:mt-20 lg:mb-12 mt-0 lg:w-[225px] w-[155px] mb-6"
                    />
                    <div className="block lg:hidden -mx-4">
                        <WebpImage
                            width={787}
                            height={800}
                            fallback="/storage/static/kusocinskiego-triple.jpg"
                            lazy
                            className="md:h-1/2 lg:h-auto md:mx-auto lg:mx-0"
                            alt="Kusociński 3 zdjęcia"
                        />
                    </div>
                    <div className="lg:glass-style unset-glass p-12 maxMd:p-0 maxMd:mt-6 lg:absolute left-0 max-w-[1060px] w-full xl:bottom-4 lg:-bottom-[25%] static">
                        <h2 className="text-h2 leading-h2 text-primary uppercase font-bold mb-8 maxMd:mb-6">
                            <span className="text-secondary">Wybór</span> na medal
                        </h2>
                        <p className="text-text text-p leading-p mb-6 maxMd:mb-4 [&>strong]:text-primary [&>strong]:font-extrabold">
                            Ulica, przy której zlokalizowana jest inwestycja, nosi imię <strong>Janusza Kusocińskiego</strong> – wybitnego polskiego lekkoatlety, złotego medalisty olimpijskiego igrzysk w 1932 roku w Los Angeles. Duch doskonalenia się i zdrowej rywalizacji jest nam w Unidevelopment bardzo bliski. Dlatego postawiliśmy na wysoką jakość wykończenia budynku, atrakcyjne układy mieszkań oraz pełne naturalnego światła przestrzenie.
                        </p>
                        <p className="text-text text-p leading-p [&>strong]:text-primary [&>strong]:font-extrabold">
                            Na terenie inwestycji zagości sportowy duch:<br/> na dziedzińcu znajdzie się teren rekreacyjny z elementami sportowymi oraz przestrzeń do wspólnego spędzania czasu i odpoczynku.
                        </p>
                    </div>
                </Column>
                <Column xl={6} lg={6} className="hidden lg:block">
                    <WebpImage
                        width={787}
                        height={800}
                        fallback="/storage/static/kusocinskiego-triple.jpg"
                        lazy
                        alt="Kusociński 3 zdjęcia"
                    />
                </Column>
            </Row>
        </Container>
    )
}

export default ChoiceOnMedal;