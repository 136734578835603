import { GalleryModel } from "@/types/models/gallery";
import { RefObject, Suspense, lazy, useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import { register } from 'swiper/element/bundle';
import { SwiperProps, SwiperRef } from "swiper/react";
import Column from "../Grid/Column";
import Container from "../Grid/Container";
import Row from "../Grid/Row";
import ReactCurvedText from "react-curved-text";
import { Link } from "@inertiajs/react";
import useIsServer from "@/Hooks/useIsServer";

register();

const Loader = () => {
    return (
        <p>Ładowanie...</p>
    )
}

const SuspensedCurvedText = () => {
    const [Component, setComponent] = useState(() => Loader)

    useEffect(() => {
        // @ts-expect-error
        setComponent(() => lazy(() => import('react-curved-text')))
    }, [])

    return (
        <Suspense fallback={<Loader />}>
            <Component
                // @ts-ignore
                width={200}
                height={109}
                cx={100}
                cy={5}
                rx={100}
                ry={100}
                startOffset={62}
                reversed={false}
                text='PRZEJDŹ DO GALERII'
                textProps={{ "style": { "fontSize": "16px", letterSpacing: '1.5px' } }}
                textPathProps={{ "fill": "#004053" }}
                tspanProps={null}
                ellipseProps={null}
                svgProps={null} />
        </Suspense>
    )
}

const GallerySlider = ({ images }: { images: GalleryModel[] }) => {
    const swiperRef = useRef<SwiperRef>(null);
    const [realIndex, setRealIndex] = useState(1);
    const isServer = useIsServer();

    const getFormat = (filename: string) => {
        return filename.split('.').pop();
    };

    useEffect(() => {
        // @ts-ignore
        if (images) {
            const swiperContainer = swiperRef.current;
            const params: SwiperProps = {
                slidesPerView: 1.2,
                centeredSlides: false,
                grabCursor: false,
                speed: 750,
                autoplay: {
                    delay: 5000,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3.6,
                        centeredSlides: true,
                    },
                    769: {
                        slidesPerView: 2.6,
                        centeredSlides: true,
                    },
                },
                spaceBetween: 24,
                loop: true,
                on: {
                    realIndexChange: (swiper: Swiper) => {
                        setRealIndex(swiper.realIndex + 1);
                    },
                },
            };
            // @ts-ignore
            Object.assign(swiperContainer, params);
            // @ts-ignore
            swiperContainer.initialize();
        }
    }, [swiperRef])

    return (
        <section className="mb-26">
            <Container>
                <Row>
                    <Column>
                        <h4 className="text-h2 leading-h2 text-primary font-extrabold maxMd:text-center uppercase mb-8">Galeria <span className="text-secondary">Kusocińskiego</span></h4>
                    </Column>
                </Row>
            </Container>
            <swiper-container ref={swiperRef as RefObject<any>} init={false}>
                {images.map((slide, i) => (
                    <swiper-slide key={i}>
                        <picture>
                            <source
                                srcSet={`${slide.photo.replace(/\.[^.]+$/, '')}.webp`}
                                type="image/webp"
                            />
                            <source
                                srcSet={`${slide.photo.replace(/\.[^.]+$/, '')}.${getFormat(slide.photo)}`}
                                type={`image/${getFormat(slide.photo)}`}
                            />
                            <img className="w-full h-[592px] maxMd:h-[496px] object-cover" src={slide.photo} alt={`Zdjęcie ${i + 1} - Kusocińskiego`} />
                        </picture>
                    </swiper-slide>
                ))}
            </swiper-container>
            <Container className="">
                <Row>
                    <Column xl={5} lg={5} md={5} className="relative">
                        <div className="lg:ml-auto mx-auto relative z-10 transition-all ease-in-out duration-300 hover:-translate-y-4 cursor-pointer w-[200px]">
                            <div className="text-right relative z-10 -mt-16 maxMd:text-center">
                                <Link href={route('frontend.gallery')} as="button" className="glass-style p-7 rounded-full mr-0 lg:mr-[52px] relative z-10" title="Galeria zdjęć">
                                    <img src="/storage/icons/camera-icon.svg" alt="Ikona metraż" width={64} height={64} />
                                </Link>
                                <div className='absolute lgMd:-right-[20%] lg:right-3 -bottom-10 right-[0%]'>
                                    <SuspensedCurvedText/>
                                </div>
                            </div>
                        </div>
                    </Column>
                </Row>
            </Container>
        </section>
    )
}

export default GallerySlider;